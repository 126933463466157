import React from "react";
import Headerp from "./../../images/People_Person2.png";

function Header() {
  return (
    <div className="header-blue ">
      <div className="max-w-7xl m-auto md:py-16 py-12 px-8">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
          <div className="text-white">
            <div className="mb-2">
              <span className="font-medium md:text-xl text-lg leading-6 tracking-wider">
                ABOUT US VONAGE STORIES
              </span>
            </div>
            <h1 className=" font-medium  lg:text-6xl md:text-5xl text-4xl mb-4 leading-none">
              Vonage Stories
            </h1>
            <p>
              Serving up the latest news, thought leadership, and information
              about Vonage.
            </p>
          </div>
          <div className="justify-self-center">
            <img src={Headerp} alt="" className="w-44" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;

import React from "react";

function Content() {
  return (
    <div>
      <div className="max-w-7xl m-auto py-12 px-4 md:py-16">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-20">
          <div>
            <a
              href="/"
              className="font-semibold text-sm border-black border-b-2 "
            >
              {" "}
              WHY CUSTOMER EXPECTATIONS FOR ENGAGEMENT WITH BUSINESSES HAS
              CHANGED FOREVER, NOVEMBER 9, 2021
            </a>
            <p className="font-normal text-base mt-4">
              With around half of consumers worldwide increasing their use of
              digital channels to engage with businesses over the past 12-18
              months and 87 percent expecting to maintain or increase this level
              in the next 6-12 months, Vonage’s research launched today
              reinforces the need for businesses to transform their customer
              engagement strategies to become more agile and adapt to permanent
              changes accelerated by the COVID era.
            </p>
          </div>
          <div>
            <a
              href="/"
              className="font-semibold text-sm border-black border-b-2 "
            >
              {" "}
              WHY CUSTOMER EXPECTATIONS FOR ENGAGEMENT WITH BUSINESSES HAS
              CHANGED FOREVER, NOVEMBER 9, 2021
            </a>
            <p className="font-normal text-base mt-4">
              With around half of consumers worldwide increasing their use of
              digital channels to engage with businesses over the past 12-18
              months and 87 percent expecting to maintain or increase this level
              in the next 6-12 months, Vonage’s research launched today
              reinforces the need for businesses to transform their customer
              engagement strategies to become more agile and adapt to permanent
              changes accelerated by the COVID era.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content;
